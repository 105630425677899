import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Clarity from '@microsoft/clarity';

const projectId = "pfibikw815"

Clarity.init(projectId);

createApp(App).use(router).mount('#app');
